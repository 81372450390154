<template>
  <div>
    <header :class="isRed ? 'style-red' : ''">
      <div class="header-box">
        <a href="javascript:;" class="btn-back" :class="icon" v-if="icon"><span @click="$router.back(-1)"></span></a>
        <h3 v-if="title">{{ title }}</h3>
        <slot></slot>
      </div>
    </header>
    <div class="g-header-space">
      <div class="header-space"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    isRed: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
    }
  },
  mounted() { },
  methods: {},
}
</script>

<style>
header {
  position: absolute;
  z-index: 888;
  left: 10px;
  width: 100%;
  top: 0;
  padding-top: 0px;
  padding-top: calc(0px + constant(safe-area-inset-top));
  padding-top: calc(0px + env(safe-area-inset-top));
  min-height: 0.98rem;
  background-color: #1778fb;
  color: #fff;
  z-index: 997;
}

header h3 {
  text-align: center;
  line-height: 0.98rem;
  font-size: 18px;
  font-family: MiSans-Semibold, MiSans;
  font-weight: 600;
  color: #FFFFFF;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bold;
}

header.style-red {
  background-color: #ff4800;
}

header .btn-back {
  width: 0.5rem;
  height: 0.5rem;
  font-size: 0.5rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
}

header .btn-back:after {
  content: "\e75b";
  font-family: "uc-font";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

header .btn-back.close:after {
  content: "\e67f";
}

header .btn-back {
  left: 0.2rem;
}

.g-header-space {
  padding-top: 0px;
  padding-top: calc(0px + constant(safe-area-inset-top));
  padding-top: calc(0px + env(safe-area-inset-top));
}

.g-header-space .header-space {
  height: 0.98rem;
}

.g-header-space {
  padding-top: 0px;
  padding-top: calc(0px + constant(safe-area-inset-top));
  padding-top: calc(0px + env(safe-area-inset-top));
}

.g-header-space .header-space {
  height: 0.98rem;
}

header .header-box {
  height: 0.98rem;
  padding: 0 0.3rem;
  position: relative;
}

header .header-box {
  height: 0.98rem;
  padding: 0 0.3rem;
  position: relative;
}
</style>



