<template>
  <div class="c-contact">
    <div class="part">
      <span class="txt">客服电话</span>
      <div class="con">
        <a href="tel:400-1885-167" rel="external nofollow" class="uc-blue tel">400-1885-167<span
            class="btn-tel">点击拨号</span></a>
      </div>
    </div>
    <div class="part">
      <span class="txt">客服微信</span>
      <div class="con">
        <a href="https://work.weixin.qq.com/kfid/kfcd1566463de25ca9c" rel="external nofollow" class="uc-blue tel"><span
            class="tel-btn btn-tel">点击咨询</span></a>
        <!-- <div class="code-box">
          <div class="code"><img src="https://hangzhou-ui.oss-cn-hangzhou.aliyuncs.com/contact-code-2.jpg"
              class="uc-icon-qw" alt=""></div>
        </div>
      <div class="tips">微信扫码，了解更多后续服务。</div> -->
      </div>
    </div>
    <slot name="button"></slot>
  </div>
</template>
<script>
export default {
  name: 'ucContact',
  components: {},
  data() {
    return {}
  },
  mounted() { },
  methods: {},
}
</script>

<style lang="scss">
.btn-tel {
  width: 78px;
  height: 26px;
  background: #3E73FE;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  display: inline-block;
  font-size: 18px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 6px;
  line-height: 26px;
  text-align: center;
}

.c-contact {
  border-radius: 0.05rem;
  background-color: #fff;
  -webkit-box-shadow: 0 -0.05rem 0.24rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 -0.05rem 0.24rem 0 rgba(0, 0, 0, 0.1);
  padding: 20px 10px;
  width: 355px;
  margin: 0 auto;
  border-radius: 4px;
}

.c-contact .part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #3D3D3D;
}

.c-contact .part:last-child {
  margin-bottom: 0;
}

.c-contact .txt {
  margin-right: 0.3rem;
  font-size: 18px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #3D3D3D;
}

.c-contact .con {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

.c-contact .btn {
  margin-left: 0.2rem;
  color: #999999;
}

.c-contact .code-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.c-contact .code {
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
}

.c-contact .tips {
  font-size: 14px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #BFBFBF;
}

.c-contact .uc-btn {
  font-size: 0.36rem;
  font-weight: bold;
  margin-top: 0.2rem;
  height: 0.8rem;
}

.uc-icon-qw {
  width: 100px;
  height: 100px;
}

.tel {
  font-size: 18px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #3E73FE;
}
</style>